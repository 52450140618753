import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';

import { InformationSecurityContent } from '@pages-impl/information-security-policy';

import * as styles from './iscp.module.scss';

export default function Index(props) {
  return (
    <Layout {...props} oldPage>
      <SEO
        noIndex={true}
        title="Information Security Policy - CodiLime"
        description="Information Security Policy CodiLime Sp. z o.o. (hereinafter CodiLime) is a networking industry expert and the first-choice service partner for top global networking hardware &amp; software providers and telecoms. CodiLime provides services in the field of software engineering, DevOps, and network engineering. This type of activity imposes obligations on the company to ensure information security. &hellip;"
      />
      <Header
        title="Information Security Policy"
        id="information-security-policy-header"
        src="bg.jpg"
        className={styles.header}
      />
      <InformationSecurityContent />
    </Layout>
  );
}
