import React from 'react';
import { Link } from '@commons/link/Link';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

const listContent = [
  'protection of information against unauthorized access;',
  'confidentiality of information;',
  'integrity of information;',
  'availability of information only to authorized persons.',
];
const listInfoSecObjectives = [
  'Raise employee awareness of information security.',
  'Information systems that are reliable and resilient against vulnerabilities.',
  'Ensure accountability of access to information.',
  'Ensure the security of customer data and resources.',
  'Comprehensive and efficient security incident management.',
];
import * as styles from './isc.module.scss';

export function InformationSecurityContent() {
  return (
    <>
      <StandardSection id="information-security-policy" className={styles.section} title="Information Security Policy">
        <p>
          CodiLime Sp. z o.o. (hereinafter CodiLime) is a networking industry expert and a first-choice service partner
          for top global networking hardware & software providers and telecoms.
        </p>
        <p>
          CodiLime provides services in the field of software engineering, DevOps, and network engineering. These types
          of activities require the company to ensure information security.
        </p>
        <p>The company&apos;s policy is to ensure:</p>
        <ListRender.Ul withArrows blue>
          {listContent.map((data, index) => (
            <ListRender.Li key={index}>{data}</ListRender.Li>
          ))}
        </ListRender.Ul>
        <b>
          The Information Security Policy and Information Security Management System (ISMS) in CodiLime, are ISO 27001
          compliant.
        </b>
        <h2>Information security objectives</h2>
        <ListRender.Ul withArrows blue>
          {listInfoSecObjectives.map((data, index) => (
            <ListRender.Li key={index}>{data}</ListRender.Li>
          ))}
        </ListRender.Ul>
        <h2>Continuous improvement</h2>
        <p>
          CodiLime aims to improve information handling in practice by continuously developing the ISMS. To achieve this
          goal, regular reviews, internal audits and risk assessments are conducted. Each employee is required to report
          risks, deviations from established processes and ideas for improvement.
        </p>
        <h2>Awareness</h2>
        <p>
          All personnel are trained in information handling and are aware of information security risks. Training covers
          obligations and rights resulting from legal acts, internal policies and procedures, and specific customer
          requirements.
        </p>
        <h2>Incident handling</h2>
        <p>
          Any employee, subcontractor, or visiting customer representative who is aware of an information security
          breach is obliged to inform the information security staff by creating a report in the appropriate queue at{' '}
          <Link href="https://help.codilime.com" title="https://help.codilime.com">
            help.codilime.com
          </Link>
          , sending an email to help@codilime.com, or calling +48 22 389 51 00. For this purpose, non-employees must
          confirm their responsibility in the matter.
        </p>
        <p>
          The CodiLime Board has approved the Information Security Policy and is committed to implementing, maintaining
          and improving the ISMS.
        </p>
      </StandardSection>
    </>
  );
}
